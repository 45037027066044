// This config is generated - please do not edit it in any way.
// Check scripts/generateConfig.js if you need to modify it in any way
interface Config {
  stage: string;
  cognitoConfig: {
    UserPoolId: string;
    ClientId: string;
  };
  appSync: {
    endpoint: string;
    apiKey: string;
  };
  userSnapApiKey: string;
  amplitudeApiKey: string;
  splitApiKey: string;
  dropboxClientId: string;
  smartyKey: string;
}
const config: Config = JSON.parse(
  '{"stage":"prod","cognitoConfig":{"UserPoolId":"us-east-1_T7CCRGyKN","ClientId":"vde7v0qh2vq3e45k66uhscdt6"},"appSync":{"endpoint":"https://verify-ui.mesh.id/graphql","apiKey":"da2-5sspx4xppzdazmsxz3wuwcnzx4"},"userSnapApiKey":"479b0a18-4d77-44f7-a22b-af687c54e673","amplitudeApiKey":"2fdce7dd536abd2b25d311a1b5f85e43","splitApiKey":"6ggsgsi4mbnnjoupcaqceg6qd5fbm118askc","dropboxClientId":"c90e4189c69cd4679ca258eec22a6500","smartyKey":"192662174239023596"}'
);
export default config;
